import React from 'react'
import "./styles.css"

export const SectionFirma = () => {
    const data: string = "Nasze długoletnie doświadczenie gwarantuje Ci, że Twoja księgowość znajduje się w dobrych rękach. Nieustannie rozwijamy nasze kompetencje, aby sprostać oczekiwaniom naszych Klientów oraz dostosowywać się do zmieniającej się rzeczywistości. Wiemy, że przepisy bywają niejasne i skomplikowane dlatego my się tym zajmiemy a Ty skupisz się na najważniejszym – rozwoju."
    return (
        <div className='d-flex'>
          <div className='w-50'>{data}</div>
          <img src='firma.jpg' alt='' className='text-image'></img>
        </div>
    )
}

export const SectionOferta = () => {
    const data1: string = `Świadczymy kompleksowe usługi rachunkowe dostosowane do potrzeb klienta.
    Nie musisz przynosić dokumentów. Wystarczy, że wrzucisz skan lub zdjęcie.`  
    const data1_5: string = `Zagwarantujemy pełną opiekę nad księgami rachunkowymi, dopilnujemy dotrzymania obowiązujących terminów i spełnienia wszystkich obowiązków rozliczeniowych. Ponadto pomagamy w dopełnieniu formalności, których wymaga rejestracja firmy oraz reprezentujemy klienta w urzędach.`

    const data2 = (
    <div className='mt-2'>
      <div className='mb-1 centered-text'><strong>Prowadzimy:</strong></div>
      <div className='mx-auto d-flex'>
      <ul className='arrow-list mx-auto'>
        <li>Ryczałt od przychodów ewidencjonowanych</li>
        <li>Podatkowa książka przychodów i rozchodów</li>
        <li>Podatek VAT</li>
        <li>Pełne Księgi handlowe</li>
        <li>Kadry i płace</li>
      </ul>
      </div>
    </div>)

    const data3 = (
      <div className='mt-2 d-flex'>
          <div className='mb-3 w-33'>
            <div className='centered-text'><strong>Zadbamy:</strong></div>
            <ul className='mt-3'>
              <li>Kontrola dokumentów</li>
              <li>Bieżąca ewidencja operacji gospodarczych</li>
              <li>Krajowy system e-faktury</li>
              <li>Ewidencja przychodów, KPiR</li>
              <li>Ewidencja dla potrzeb VAT</li>
              <li>Ewidencja środków trwałych i wyposażenia</li>
              <li>Deklaracje JPK_V7</li>
              <li>Rozliczania podatku dochodowego</li>
              <li>Sporządzanie i dostarczanie do Urzędu Skarbowego rocznych zeznań PIT</li>
            </ul>
          </div>

          <div className='mb-3 w-33'>
            <div className='centered-text'><strong>Przygotujemy:</strong></div>
            <ul className='mt-3'>
              <li>Lista płac, rachunków do umowy zlecenia oraz o dzieło, </li>
              <li>Wyliczanie wynagrodzeń </li>
              <li>Rozliczanie urlopów, odpraw i ekwiwalentów</li>
              <li>Naliczanie podatek dochodowy od wypłat pracowników, </li>
              <li>Naliczanie składek ZUS </li>
              <li>Przygotowywanie dokumentów ZUS</li>
              <li>Sporządzanie  PIT-11, PIT-8B</li>
            </ul>
          </div>

          <div className='mb-3 w-33'>
            <div className='centered-text'><strong>Dopilnujemy:</strong></div>
            <ul className='mt-3'>
              <li>Rozrachunki z kontrahentami</li>
              <li>Sporządzanie do GUS sprawozdań statystycznych</li>
              <li>Podatek od środków transportu</li>
              <li>Podatek od nieruchomości </li>
            </ul>
          </div>
      </div>
    )

    return (
      <div>
        {data1}
        <br></br>
        {data1_5}
        {data2}
        {data3}
      </div>
    )
}

export const SectionCennik = () => {
  const data: string = "Ceny ustalane indywidualnie z klientem w zależności od formy działalności oraz liczby dokumentów. Zapraszamy do kontaktu telefonicznego lub mailowego. Z przyjemnością odpowiemy na wszystkie pytania."
  return (
    <div>{data}</div>
  )
}

export const SectionKontakt = () => {
  const data1: string = "Biuro Rachunkowe Liber Katarzyna Janik"
  const data2: string = "Ul. Kamienna 7, 34-600 Limanowa"
  const data3: string = "Tel. 668 777 608"
  const mail: string = "biuro.rachunkowe@biuro-liber.pl"

  return (
    
    <div className='d-flex'>
      <div className='w-50'>
        {data1}<br/>
        {data2}<br/>
        {data3}<br/>
        <a href='mailto:biuro.rachunkowe@biuro-liber.pl'>{mail}</a>
      </div>
      <img src='kontakt.jpg' alt='' className='text-image'></img>
      
    </div>
    
  )
}

const DataSections = () => {
  return (
    <div>DataSections</div>
  )
}

export default DataSections