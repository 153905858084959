import React from 'react'
import { SectionCennik, SectionFirma, SectionKontakt, SectionOferta } from './DataSections';

interface mainContentProps {
    section: string;
}

const MainContent: React.FC<mainContentProps> = ({section}) => {

    const data: {[id: string]: React.FC} = {
        "O firmie": SectionFirma,
        "Cennik": SectionCennik,
        "Oferta": SectionOferta,
        "Kontakt": SectionKontakt,
    }

    const nameMap: {[id: string]: string} = {
        "O firmie": "Odkryj naszą firmę",
        "Cennik": "Liczy się twoja firma",
        "Oferta": "Poznaj naszą ofertę",
        "Kontakt": "Skontaktuj się z nami",
    }

    return (
        <div className='container'>
            <div className='display-6 my-5 centered-text'>
                {nameMap[section]}
            </div>
            {data[section]({})}
        </div>
    )
}

export default MainContent