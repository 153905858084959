import React from 'react'
import "./styles.css"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


interface navBarProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const NavbarCustom: React.FC<navBarProps> = ({setCurrentSection}) => {
    const sections: string[] = ["O firmie", "Oferta", "Cennik", "Kontakt"];

    return (
        <Navbar expand="lg" className="bg-body-tertiary w-100">
            <Navbar.Brand>
                <img className='mx-2 w-25 d-inline' src='logo.jpg' alt='logo'></img>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='w-70'/>
            </Navbar.Brand>
            
            

            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="container d-flex justify-content-between my-3 mr-0">
                {sections.map((s) => (
                    <Nav.Item className='w-100'>
                        <button
                        className="section-button nav-item w-100 py-2 mx-1"
                        key={s}
                        onClick={() => {setCurrentSection(s)}}>
                            {s}
                        </button>
                    </Nav.Item>
                    ))}
            </Nav>
            </Navbar.Collapse>
        </Navbar>
        // <nav className="navbar navbar-expand-lg navbar-light bg-light w-100">
        //     <img className='navbar-brand img-fluid d-block mx-2 w-10' src='logo.jpg' alt='logo'></img>
        //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false">
        //         <span className="navbar-toggler-icon"></span>
        //     </button>
        //     <div className="collapse navbar-collapse w-10" id='navbarSupportedContent'>
        //         <ul className="navbar-nav container d-flex justify-content-between my-3 mr-0">
        //             {sections.map((s) => (
        //                 <li className='w-100 nav-item'>
        //                     <button
        //                         className="section-button nav-item w-100 py-2"
        //                         key={s}
        //                         onClick={() => {setCurrentSection(s)}}>
        //                             {s}
        //                     </button>
        //                 </li>
        //             ))}
        //         </ul>
        //     </div>
        // </nav>
    )
}

export default NavbarCustom